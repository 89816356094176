import { ref, watch, Ref } from 'vue'
import { useRequest } from 'vue-request'
import {
  BaseResponse,
  TeamDetail,
  BasePagination,
  TeamQueryDto,
  ITeam
} from '@/_modules/types'

import { teamService } from '@/_services'
interface ReturnType {
  repositories: Ref<ITeam[]>;
  loading: Ref<boolean>;
  getTeamRepositories: (q: TeamQueryDto) => Promise<ITeam[] | null>;
  getTeamRepository: (id: number) => Promise<ITeam | null>;
}
export default function useTeamRepositories (q?: Ref<TeamQueryDto>): ReturnType {
  const repositories = ref<ITeam[]>([])
  const cb = (ele: TeamDetail) => {
    const { id, setting_team_informations, global_config_local_types, setting_team_region_country, setting_team_social_contacts, setting_team_regions_name } = ele
    const {
      setting_team_media_profile_url: logo,
      setting_team_information_name_en: en,
      setting_team_information_name_th: th,
      setting_team_information_abbreviation: abbreviation,
      setting_team_information_established: established,
      setting_team_information_nickname: nickname,
      setting_team_information_history_th: history
    } = setting_team_informations
    const { global_config_local_type_name_th: nationCategory } = global_config_local_types
    const dataRespon: ITeam = {
      id,
      name: {
        en: en ?? '-',
        th: th ?? '-',
        abbreviation,
        established,
        nickname: nickname ?? '-'
      },
      country_th: setting_team_region_country?.country_name_th ?? '-',
      country_en: setting_team_region_country?.country_name_en ?? '-',
      logo,
      nationCategory,
      history,
      contact: {
        setting_team_social_contact_email: setting_team_social_contacts?.setting_team_social_contact_email ?? '-',
        setting_team_social_contact_facebook: setting_team_social_contacts?.setting_team_social_contact_facebook ?? '-',
        setting_team_social_contact_fax: setting_team_social_contacts?.setting_team_social_contact_fax ?? '-',
        setting_team_social_contact_line: setting_team_social_contacts?.setting_team_social_contact_line ?? '-',
        setting_team_social_contact_phone: setting_team_social_contacts?.setting_team_social_contact_phone ?? '-',
        setting_team_social_contact_instagram: setting_team_social_contacts?.setting_team_social_contact_instagram ?? '-',
        setting_team_social_contact_twitter: setting_team_social_contacts?.setting_team_social_contact_twitter ?? '-',
        setting_team_social_contact_website: setting_team_social_contacts?.setting_team_social_contact_website ?? '-',
        setting_team_social_contact_youtube: setting_team_social_contacts?.setting_team_social_contact_youtube ?? '-',
      },
      address: {
        address: setting_team_region_country?.setting_stadium_region_address_detail ?? '-',
        subdistrict: setting_team_region_country?.sub_district_name_th ?? '-',
        district: setting_team_region_country?.district_name_en ?? '-',
        province: setting_team_region_country?.province_name_th ?? '-',
        country: setting_team_region_country?.country_name_th ?? '-',
        zipcode: setting_team_region_country?.setting_team_region_zipcode ?? '-',
      },
    }
    return dataRespon
  }
  const formatResult = (result: BaseResponse<BasePagination<TeamDetail[]>>) => {
    const { teams } = result.data ?? result
    if (!teams?.data) return []
    return teams.data?.map(cb)
  }
  const { run: getTeamRepositories, loading } = useRequest(
    teamService.list,
    {
      manual: true,
      formatResult,
      onSuccess: formattedData => {
        repositories.value = formattedData
      }
    }
  )


  const { run: getTeamRepository } = useRequest(
    teamService.getById,
    {
      manual: true,
      formatResult: ({ teams }: any) => {
        return cb(teams)
      }
    }
  )
  watch(() => q?.value, () => {
    if (q?.value) {
      getTeamRepositories(q.value)
    }
  }, { immediate: true })

  return {
    repositories,
    loading,
    getTeamRepositories,
    getTeamRepository
  }
}
